.Header {
  $self: &;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;

  &--slim-wrapper {
    position: fixed;
    background-color: white;
    top: 0;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
    z-index: 1040;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    #{$self} {
      padding: 0;
    }

    #{ $self }__logo__image {
      width: 32px;
      margin: 0.5rem 0;
      // width: 175px;
    }
  }

  &__logo {
    flex: 0 0 25%;

    &__image {
      width: 215px;

      // #{$self}--slim {
      //   width: 175px;
      // }
    }
  }

  &__navigation {
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__social {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
    flex: 0 0 25%;
  }

  &__mobile-navigation {
    display: flex;
    justify-content: flex-end;
    // flex-grow: 1;
    // height: 100%;
    // align-items: center;
    // justify-content: center;
  }

  &__mobile-menu {
    &__social {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
    }
  }
}
