@import "../../styles/colors";

.BeerList {
  display: flex;
  flex-direction: column;

  .BeerListItem:not(:last-of-type) {
    border-bottom: 1px solid $gray-5;
  }
}
