.BodsCalendar {
  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    &__title {
      margin: 0;
    }
  }

  &.rbc-calendar {
    height: unset !important;
  }

  .rbc-month-row {
    overflow: visible;
  }

  .rbc-row-content {
    min-height: 150px;
  }
  .rbc-event {
    background-color: transparent;
    padding: 2px;
  }
  .rbc-event-content {
    white-space: unset;
  }
}
