.DayBlock {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f6f9;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 6px 12px 0;

  &__month {
    text-transform: uppercase;
    color: #575757;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  &__day {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
  }
}
