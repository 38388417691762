.BodsIcon {
  svg {
    --color-primary: #222222;

    fill: none;
    stroke: var(--color-primary);
    color: var(--color-primary);
    strokeWidth: 1px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &--square {
    background-color: #f2f6f9;
    padding: 0.5rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: cnter;
  }
}
