.BodsModalBody {
  height: 100%;
  padding: 1.5rem;

  // &--full {
  //   padding: 0px;
  // }

  &--sm,
  &--md,
  &--lg {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
