@import "../../styles/colors";

.EventCard {
  $self: &;
  text-decoration: none;
  color: initial;

  &--slim {
    > *,
    #{$self}__description__text {
      font-size: 14px !important;
    }
  }

  &__card {
    // cursor: pointer;
    // margin-bottom: 2rem;
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;

    &__image {
      overflow: hidden;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      transition: scale 0.3s ease;
    }

    &__day {
      position: absolute;
      z-index: 1;
      bottom: 0;
      padding: 1rem;
    }
  }

  &__title {
    color: $gray-1;
  }

  &__attribute {
    margin-bottom: 1rem;
  }

  &__link {
    word-break: break-word;
  }

  &:hover {
    color: initial;
    #{$self}__image-wrapper__image {
      scale: 110%;
    }
  }
}
