@import "../../../styles//colors";

.BodsBanner {
  color: $white-0 !important;
  background-color: $blue-primary;
  // background-color: $gray-1;
  padding: 0.75rem;
  display: flex;
  justify-content: center;

  &__link {
    margin-left: 1rem;
    color: $white-0 !important;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}
