@import "../../../styles/colors";

.BodsAlert {
  background-color: $system-yellow;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;

  &__text {
    margin-left: 1rem;
    margin-bottom: 0;
    color: $gray-0;
  }

  &--left {
    justify-content: start;
  }

  &--center {
    justify-content: center;
  }
}
