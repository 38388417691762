.HeroCarousel {
  &__slide__image {
    padding: 200px 0;

    @media screen and (max-width: 991px) {
      padding: 175px 0;
    }

    @media screen and (max-width: 767px) {
      padding: 125px 0;
    }
  }
}
