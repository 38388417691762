.IconAttribute {
  display: flex;
  align-items: center;
  // margin-bottom: 1rem;

  &__value {
    margin-left: 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
