.Seperator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16px;

  &__dot {
    width: 2px;
    height: 2px;
    background: white;
    border-radius: 50%;
  }
}
