
.BodsModal {
  $self: &;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1050;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;

  &--size-full {
    z-index: 1047;
  }

  &--open {
    #{$self}__content {
      transform: none !important;
    }
  }

  &__content {
    background: white;
    transition: transform 0.3s ease-out;
    z-index: 1049;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--size-full {
      width: calc(100% + 2rem);
      height: 100vh;
      background-color: white;
      overflow-y: auto;
    }

    &--size-sm {
      width: 500px;
      max-height: calc(100vh - 8rem);
    }

    &--size-md {
      width: 700px;
      max-height: calc(100vh - 8rem);
    }

    &--size-lg {
      width: 900px;
      max-height: calc(100vh - 8rem);
    }

    &--size-lg,
    &--size-md,
    &--size-sm {
      height: fit-content;
      margin: auto 1rem;
      transform: translateY(-50px);
      border-radius: 0.5rem;
    }
  }
}

.Body--BodsModal-opened {
  overflow: hidden;
}
