$gray-0: #0f1825;
$gray-1: #192638;
$gray-2: #374253;
$gray-3: #596579;
$gray-4: #7b8698;
$gray-5: #a7afbc;
$gray-6: #d0d7e2;
$gray-7: #e6eaf0;
$gray-8: #f7f9fc;
$gray-9: #fafbfc;

// $blue-0: #002762;
// $blue-1: #01337E;
// $blue-2: #0043A8;
// $blue-3: #0A59CF;
// $blue-4: #1E70EB;
// $blue-5: #468FFC;
// $blue-6: #83BDFF;
// $blue-7: #C6E2FF;
// $blue-8: #F0F6FF;
// $blue-9: #F7FAFF;
$blue-0: #040127;
$blue-1: #09024f;
$blue-2: #0b0363;
$blue-3: #0d0377;
$blue-4: #11049f;

$system-yellow: #fff7bc;

$blue-primary: #23374d;

$white-0: #ffffff;
