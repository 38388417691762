// @import "_reset.scss";
// @import "_mixins.scss";
// @import url("https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,700|Hind:400,500,600,700|Quattrocento:400,700");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");
// body {
//   font-family: "Barlow", "Hind", sans-serif;

/*------------------------------------*\
    # Simple Typography Settings
\*------------------------------------*/

// Font Family
// $font-family: "Barlow", "Hind", sans-serif;
$font-family: "Courier Prime", monospace;

// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color: #505050;
$paragraph-size: rem(16px);
$paragraph-line-height: 1.5em;
$paragraph-margin: 16px;

// Header Styles
$header-color: #000000;
$header-size: rem(40px);
$header-line-height: 1.25em;
$header-margin: $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color: #505050;
$blockquote-size: rem(23px);
$blockquote-line-height: 1.5em;
$blockquote-margin: $paragraph-margin * 1.5;

// Links
$link-color: #459bd9;

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 800;
$border-color: #dadada;

/*------------------------------------*\
    # Type
\*------------------------------------*/

// Make type normal across browsers
*,
html,
body {
  font-size: $base-font-size;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: $header-margin * 1.5;
  // color: $header-color;
  line-height: $header-line-height;
  font-weight: 400;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: $header-size;
  margin-bottom: $header-margin;
}

h2 {
  font-size: calc($header-size / 1.3);
  margin-bottom: calc($header-margin / 1.3);
}

h3 {
  font-size: calc($header-size / 1.5);
  margin-bottom: calc($header-margin / 1.5);
}

h4 {
  font-size: calc($header-size / 1.7);
  margin-bottom: calc($header-margin / 1.7);
}

h5 {
  font-size: calc($header-size / 1.8);
  margin-bottom: calc($header-margin / 1.8);
}

h6 {
  font-size: calc($header-size / 2);
  margin-bottom: calc($header-margin / 2);
}

// Paragraphs
p {
  margin-bottom: $paragraph-margin;
  font-size: $paragraph-size;
  line-height: $paragraph-line-height;
  color: $paragraph-color;
  font-weight: 500;

  // &:last-of-type {
  //   margin-bottom: 0;
  // }
}

// Links
a {
  color: $link-color;
  transition: all 0.2s ease;

  &:hover {
    color: darken($link-color, 20%);
  }
}

// Lists
ul,
ol {
  margin: $paragraph-margin 0 0 20px;

  li {
    margin-top: 10px;
    line-height: $paragraph-line-height;
    color: $paragraph-color;
  }

  ul,
  ol {
    margin-top: 0;
  }
}

ul {
  li {
    list-style: disc;
  }

  ul {
    li {
      list-style: circle;
    }
  }
}

ol {
  li {
    list-style: decimal;
  }
}

// hr
hr {
  height: 1px;
  margin: $hr-margin 0;
  border: none;
  background-color: $border-color;
}

// Blockquote
blockquote {
  margin: $blockquote-margin 0;
  font-size: $blockquote-size;
  line-height: $blockquote-line-height;
  color: $blockquote-color;
  text-align: center;
  font-style: italic;
}

// Tables
table {
  width: 100%;
  margin: $blockquote-margin 0;
  border-collapse: collapse;
}

table,
td,
th {
  border: 1px solid $border-color;
  text-align: left;
}

th {
  font-weight: $font-weight-bold;
}

th,
td {
  padding: 15px;
}

// Figure
figure {
  margin: $blockquote-margin 0;

  img {
    margin: 0;
  }
}

figcaption {
  margin-top: 10px;
  color: lighten($paragraph-color, 10%);
  font-size: $paragraph-margin * 0.8;
}

// Code
pre {
  display: block;
  margin: 0 0 40px 0;
  padding: 20px;
  background-color: lighten($border-color, 12%);
  border: 1px solid $border-color;
  overflow: auto;
  border-radius: 3px;

  code {
    position: static;
    padding: 0;
    border: none;
    line-height: 1.5em;
  }
}

code {
  position: relative;
  top: -0.2em;
  padding: 3px;
  font-family: Courier New, Courier, monospace;
  font-size: calc($paragraph-size / 1.2);
  color: darken($border-color, 70%);
  line-height: 1em;
  pointer-events: none;
  border: 1px solid $border-color;
  border-radius: 3px;
}

// Misc Styles
em,
i,
.italic {
  font-style: italic;
}

strong,
b,
.bold {
  font-weight: $font-weight-bold;
}

// img {
//   display: block;
//   max-width: 100%;
//   margin: $blockquote-margin 0;
// }
