.Row {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;

  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 16px;
    padding-left: 16px;
  }

  &--center {
    align-items: center;
  }
}
