.SocialLinks {
  display: flex;

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-of-type) {
      margin-left: 0.5rem;
    }
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }

    // &::after {
    //   content: " ";
    //   width: 40px;
    //   height: 40px;
    //   border-radius: 100%;
    //   // background-color: aqua;
    //   position: absolute;
    //   z-index: -1;
    // }

    // &--untappd {
    //   &::after {
    //     background-color: #ffc000;
    //   }
    // }
  }
}
