@import "../../styles/colors";

.BeerListItem {
  padding: 1rem 0.5rem;

  &__meta {
    color: $gray-1;
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    &__abv,
    &__style {
      margin: 0;
    }

    &__dot {
      width: 5px;
      height: 5px;
      background-color: $gray-3;
      border-radius: 100%;
      margin: 0 1rem;
      display: inline-block;
    }
  }

  &__description {
    margin-bottom: 0;
  }
}
