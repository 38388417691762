// // @import './styles/main.scss';
// // @import './styles/colors';
@import './styles//main';

// @import url("https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,700|Hind:400,500,600,700|Quattrocento:400,700");
body {
  // font-family: "Barlow", "Hind", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: $gray-0;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
