.BodsCardBody {

  &--padding-0 {
    padding: 0px;
  }
  &--padding-0-25 {
    padding: 0.25rem;
  }
  &--padding-0-50 {
    padding: 0.5rem;
  }
  &--padding-0-75 {
    padding: 0.75rem;
  }
  &--padding-1 {
    padding: 1rem;
  }
}
