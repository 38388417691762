@import "../../styles/colors";

.NavigationItem {
  margin: 0 1rem;
  font-weight: 500;
  color: $gray-1 !important;
  text-decoration: none;
  // font-size: 1.25rem;
  padding: 0.5rem;
  flex-shrink: 0;

  @media screen and (max-width: 991px) {
    padding: 1.25rem 0.5rem;
    margin-bottom: 0.5rem;
  }

  &--active {
    color: $white-0 !important;
    background-color: $blue-primary;
    // background-color: $gray-1;
    border-radius: 4px;
    font-weight: 700;
  }
}
