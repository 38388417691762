.PageLayout {
  display: flex;
  flex-direction: column;
  // height: 100vh;

  &__content {
    flex-grow: 1;
    min-height: calc(100vh - 107px);
  }
}
