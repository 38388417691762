@import "../../styles/colors";

.Over21ConfirmationModal {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  > div {
    flex: 0 0 50%;
  }

  &__logo-wrapper {
    background-color: $blue-primary;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    margin-bottom: -1.5rem;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    // border-top-left-radius: 8px;
    // border-bottom-left-radius: 8px;
    // border-radius: 8px;

    display: flex;
    justify-content: center;

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      margin-right: -1.5rem;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 8px;
      min-height: 100px;
      padding-bottom: 1rem;
    }

    &__img {
      width: 300px;

      @media screen and (max-width: 767px) {
        width: 200px;
        margin: 1rem;
      }
    }
  }

  &__details {
    padding-left: 1.5rem;

    @media screen and (max-width: 767px) {
      padding-left: 0;
      padding-top: 1.5rem;
    }

    &__question {
      margin: 2rem 0;

      @media screen and (max-width: 767px) {
        margin: 1rem 0;
      }
    }
  }
}
