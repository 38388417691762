
.BodsModalFooter {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 0.75rem 1rem;

  * {
    margin-bottom: 0 !important;
  }
}
