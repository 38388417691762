.Container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 1rem;
  padding-right: 1rem;

  &--fluid {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &--paddingY {
    &-0 {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-1 {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    &-1-5 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    &-2 {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    &-2-5 {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    &-3 {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
    &-3-5 {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
    &-4 {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
}
