.BeerPage {
  &__image-container {
    padding: 100px 0;

    @media screen and (max-width: 767px) {
      padding: 70px 0;
    }
  }

  &__title {
    padding-top: 1rem;
    text-align: center;
  }
}
