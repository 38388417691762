.EventListItem {
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }

  &__day-box {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f6f9;
    width: 62px;
    height: 62px;
    border-radius: 8px;
    margin-right: 1rem;

    &__month {
      text-transform: uppercase;
      color: #575757;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    &__day {
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
    }
  }

  &__details {
    flex-grow: 1;

    &__name {
      // font-weight: 400;
      margin-bottom: 8px;
      text-align: left;
    }

    // &__description {
    //   font-size: 1.25rem;
    // }

    &__attribute {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;

      &__value {
        margin-left: 0.75rem;
      }
    }
  }
}
