@import "../../styles//colors";

.PageTitle {
  padding: 2rem 0;
  text-align: center;

  &__title {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding: 1rem 0;
  }
}
