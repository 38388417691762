@import "../../styles/colors";

.PrivateEventsPage {
  &__faqs {
    &__title {
      text-align: center;
    }
  }

  &__ready {
    padding: 3rem 0;
    background-color: $gray-7;
    text-align: center;

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
