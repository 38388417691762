// @import '../../colors/_colors.scss';

.BodsBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  z-index: 1048;

  &--open {
    opacity: 0.5;
  }

  &--transparent {
    background-color: transparent !important;
    opacity: 1 !important;
  }
}
