@import "../../../styles/colors";

.BodsImageCard {
  // background: url("../../images/tanks_hero_1.png") center center;
  // padding: 170px 0;
  // background-size: cover;
  // align-self: center;
  // position: relative;
  // background-position: center center;
  border-radius: 16px;
  background-color: $gray-7;
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover !important;
  background-position: 50% !important;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   left: 0px;
  //   top: 0px;
  //   width: 100%;
  //   height: 100%;
  //   background: #fff none repeat scroll 0 0;
  //   opacity: 0.85;
  //   // clip-path: polygon(0 0, 85% 0, 80% 100%, 0% 100%);
  // }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
  }

  &--hover {
    transition: scale 0.3s ease;
    &:hover {
      scale: 1.1;
    }
  }
}
