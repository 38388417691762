.BoCollapsible {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  transition-property: max-height;
  transition-duration: duration(fast);
  transition-timing-function: easing(out);

  // Stop children from being focused when fully closed
  &--isFullyClosed {
    display: none;
  }
}
