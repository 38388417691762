@import "../../../styles/colors";

.BodsDrawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1049;

  &__content__wrapper {
    z-index: 1049;
    position: absolute;
    display: flex;
    right: 0;
    height: 100vh;
    padding: 0.5rem;
    width: 425px;

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  &__content {
    border-left: $gray-5;
    height: 100%;
    padding-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white-0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
    border-radius: 0.5rem;

    &__header {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5rem;

      &__title {
        margin-bottom: 0;
        font-weight: 600;
        color: $gray-1;
      }

      &__close-btn {
        margin-bottom: 0.25rem;
      }
    }

    &__body {
      padding: 1rem;
      overflow: auto;
      flex-grow: 1;
    }
  }

  &__animation {
    &-enter {
      transform: translateX(425px);
    }

    &-enter-active {
      transform: translateX(0px);
      transition: transform 350ms ease-in-out 0s;
    }

    &-enter-done {
      transform: translateX(0px);
    }
    &-exit {
      transform: translateX(0px);
    }
    &-exit-active {
      transform: translateX(425px);
      transition: transform 350ms ease-in-out 0s;
    }
  }
}

.Body--BodsDrawer-opened {
  overflow: hidden;
}
