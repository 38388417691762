@import "../../styles/colors";

.PrivateEventMenuItem {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.5rem;
  border-radius: 8px;

  &__title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;

    &__dot {
      width: 5px;
      height: 5px;
      background-color: $gray-9;
      border-radius: 100%;
      margin: 0 1rem;
      display: inline-block;
      margin-bottom: 1rem;
    }

    &__abv {
      font-size: 60%;
    }
  }

  &__desc {
    font-size: 16px;
    margin-bottom: 0;
  }

  > * {
    color: $gray-9;
  }
}
