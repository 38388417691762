.TaproomPage {
  &__map-responsive {
    background-color: lightgray;
    overflow: hidden;
    position: relative;
    height: 500px;
    border-radius: 16px;

    @media screen and (max-width: 576px) {
      height: 300px;
      margin-bottom: 2rem;
    }

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  &__address {
    margin-bottom: 4rem;
  }

  &__faqs {
    &__title {
      text-align: center;
    }

    // &__list {
    //   // max-width: 576px;
    //   max-width: 762px;
    //   margin-left: auto;
    //   margin-right: auto;
    // }
  }
}
