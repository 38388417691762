@import "../../../styles/colors";

.BodsButton {
  display: flex;
  align-items: center;
  appearance: none;
  background-color: $gray-6;
  color: $gray-0;
  padding: 0.75rem 1rem;
  border: none;
  font-size: 1rem;
  border-radius: 40px;
  cursor: pointer;
  user-select: none;

  &--blue {
    background-color: $blue-primary;
    color: white;
  }

  &--white {
    background-color: $white-0;
    color: $blue-primary;
  }

  &--gray {
    background-color: $gray-6;
    color: $gray-0;
  }

  &--tertiary {
    background-color: transparent !important;
    color: $gray-0;
  }

  &--icon {
    border-radius: 100%;
    padding: 0.6875rem;
  }
}
