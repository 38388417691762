@import "../../../styles//colors";

.BodsDivider {
  display: flex;
  align-items: center;

  &__divider {
    border-top: 1px solid $gray-5;
    flex-grow: 1;
  }

  &__text {
    margin: 0 1rem;
    color: $gray-2;
  }
}
