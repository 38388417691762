@import "../../styles/colors";

.HomePage {
  &__info-card:not(:last-of-type) {
    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem;
    }
  }

  &__feature-column {
    @media screen and (max-width: 991px) {
      margin-bottom: 1.5rem;
    }
  }

  &__feature-card {
    overflow: hidden;
    border-radius: 16px;

    a {
      text-decoration: none !important;
    }

    &__content {
      padding: 8rem 0;
      text-align: center;

      @media screen and (max-width: 991px) {
        padding: 5rem 0;
      }

      &__title {
        margin-bottom: 0;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        text-shadow: 1px 3px 6px rgba(0, 0, 0, 91%);

        &::after {
          background-color: rgba(0, 0, 0, 30%);
          content: " ";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        // text-shadow: 0.03em 0.03em 0 hsla(230, 40%, 50%, 1);
        // }
        // &:after {
        //   content: attr(data-shadow);
        //   position: absolute;
        //   top: 0.06em;
        //   left: 0.06em;
        //   z-index: -1;
        //   text-shadow: none;
        //   background-image: linear-gradient(
        //     45deg,
        //     transparent 45%,
        //     hsla(48, 20%, 90%, 1) 45%,
        //     hsla(48, 20%, 90%, 1) 55%,
        //     transparent 0
        //   );
        //   background-size: 0.05em 0.05em;
        //   -webkit-background-clip: text;
        //   -webkit-text-fill-color: transparent;

        //   animation: shad-anim 15s linear infinite;
        // }
      }

      &__button {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__on-tap {
    padding-bottom: 7rem;
  }

  &__events {
    background-color: $gray-8;
    &__title {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  // &__hero {
  //   background: url("../../images/tanks_hero_1.png") center center;
  //   padding: 170px 0;
  //   background-size: cover;
  //   align-self: center;
  //   position: relative;
  //   background-position: center center;

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     left: 0px;
  //     top: 0px;
  //     width: 70%;
  //     height: 100%;
  //     background: #fff none repeat scroll 0 0;
  //     opacity: 0.85;
  //     clip-path: polygon(0 0, 85% 0, 80% 100%, 0% 100%);
  //   }

  //   &__content {
  //     position: relative;
  //     z-index: 1;
  //   }
  // }

  // &__hero__end-shape {
  //   position: relative;

  //   &__shape {
  //     position: absolute;
  //     pointer-events: none;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     color: white;
  //     overflow: hidden;

  //     > svg {
  //       transform: scale(2);
  //       width: 100%;
  //       height: auto;
  //       transform-origin: top center;
  //     }
  //   }
  // }
}

// @keyframes shad-anim {
//   0% {background-position: 0 0}
//   0% {background-position: 100% -100%}
//   }
