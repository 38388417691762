.CarouselSlider {
  // &__slide + &__slide {
  //   margin-left: 2rem;
  margin: 0 -1rem;
  // }

  &__slide {
    margin: 0 1rem;
    // padding-right: 2rem;

    // &:first-of-type {
    //   margin-left: 0;
    // }

    // &:last-of-type {
    //   margin-right: 0;
    // }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .dot-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel__dot {
        width: 8px;
        height: 8px;
        border: none;
        border-radius: 50%;
        margin: 0 4px;
        padding: 0;
        background-color: #c3c4ca;
      }

      /* This class is found in DotGroup from pure-react-carousel */
      /* We need to override it to add our styles */
      .carousel__dot--selected {
        width: 16px;
        height: 8px;
        border-radius: 10px;
        background-color: #6267a1;
        transition: background 0.4s ease;
      }
    }
  }
}
