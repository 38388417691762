.Footer {
  padding: 3rem 0 2rem 0;
  background-color: #23374d;
  // margin: 2rem 0;
  color: white !important;

  &__logo {
    width: 215px;
    @media screen and (max-width: 991px) {
      margin: auto;
    }
  }

  &__seperator {
    margin-bottom: 1rem;
  }

  &__link {
    color: white !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__column:not(:last-of-type) {
    @media screen and (max-width: 991px) {
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }
}
