.BodsEmptyState {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 2rem 1rem;

  &--border {
    border: 1px solid #D5D8DF;
  }

  &__content {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    text-align: center;
    margin: 0.5rem 0;
  }

  &__text {
    text-align: center;
    margin: 0.5rem 0;
  }

  &__button {
    margin-top: 1rem;
  }
}
