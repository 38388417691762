
.BodsModalHeader {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);

  &--has-tabs {
    padding-bottom: 0;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__tabs {
    margin-top: 1rem;
  }

  &__btn-close {
    align-self: start;
  }

  * {
    margin-bottom: 0 !important;
  }
}
