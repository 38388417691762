.VisuallyHidden {
  // Need to make sure we override any existing styles.
  // stylelint-disable declaration-no-important
  position: absolute !important;
  top: 0;
  clip: rect(1px, 1px, 1px, 1px) !important;
  overflow: hidden !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  // stylelint-enable declaration-no-important
}
