.Column {
  flex: 1 0;
  
  &--auto {
    flex: 0 0 auto;
    width: auto;
  }

  &--1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  &--2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  &--3 {
    flex: 0 0 auto;
    width: 25%;
  }

  &--4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  &--5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  &--6 {
    flex: 0 0 auto;
    width: 50%;
  }

  &--7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  &--8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  &--9 {
    flex: 0 0 auto;
    width: 75%;
  }

  &--10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  &--11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  &--12 {
    flex: 0 0 auto;
    width: 100%;
  }
}
