.EventDetails {
  &__hero {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 16px;

    &__background {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      -webkit-filter: blur(50px) brightness(0.9);
      filter: blur(50px) brightness(0.9);
      z-index: 0;
    }

    &__image {
      position: relative;
      height: 470px;
    }
  }

  &__details {
    margin: 3rem 0;

    &__attributes {
      display: none;

      @media screen and (max-width: 991px) {
        display: block;
      }
    }
  }

  &__right-details {
    &__attribute {
      margin-bottom: 1.5rem;
    }
  }
}
