@import "../../styles/colors";

.PrivateEventsMenuPage {
  background-color: $blue-primary;
  height: 100vh;
  padding: 2rem;

  color: $gray-9;

  &__menu {
    display: grid;
    grid-template-columns: calc(50% - 1rem) calc(50% - 1rem);
    gap: 1.5rem;
  }

  &__menu-slim {
    display: flex;
    gap: 2.5rem;

    &__item {
      font-size: 28px;
    }
  }

  &__logo {
    opacity: 0.02;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: calc(100% - 2rem);

    > * {
      width: 90%;
    }
  }
}
