.BodsSection {
  // padding: 0 0.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  &--boxed {
   
    max-width: 1440px;
  }

  &--full {
  }

  &--full-gutter {
    padding: 0 2rem;
  }
}
