.ContactPage {
  &__info-cards {
    display: flex;
    flex-direction: column;

    &__card {
      margin-bottom: 2rem;
    }
  }
}
